/* eslint-disable react/no-unescaped-entities */
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useState } from 'react'
import { RouteProps } from 'react-router-dom'

import FaqQuestion from '../../components/FaqQuestion'
import SEO from '../../components/seo'

type Props = RouteProps<'/suburb-faq'>

const SuburbFAQContainer: React.FC<Props> = () => {
  const { t } = useTranslation()

  const [open, setOpen] = useState('')

  const toggle = (identifier: string) => {
    setOpen(open === identifier ? '' : identifier)
  }

  return (
    <>
      <SEO title="FAQs" />
      <div className="cms-container">
        <h1 className="cms-container__title">Frequently Asked Questions</h1>
        <div className="questions-container cms-container__wrapper">
          <FaqQuestion
            customClass="question-group--first"
            question="What is MealPal?"
            open={open === '#1'}
            toggle={() => toggle('#1')}
          >
            <p>
              {t('mealpal-name')} offers a dinner membership that enables you to save up to 40% at the best restaurants
              in your town! We provide a fast, fun, and efficient system to get you delicious and affordable dinner for
              your family every weekday - while supporting local restaurants.
            </p>
            <p>
              MealPal members can reserve meals from any participating restaurant. Each restaurant offers two to four
              different meals each day. If a member is in a city that offers dinner, and has a lunch and dinner plan,
              both lunch and dinner can be reserved daily.
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="What are the membership plans and how much do they cost?"
            open={open === '#2'}
            toggle={() => toggle('#2')}
          >
            <p>
              MealPal offers various 30-day dinner memberships. With each membership plan, you will have a set number of
              credits, and use those credits to reserve meals. Each meal costs between 5 and 12 credits.
            </p>
            <p>Our two main membership options are:</p>
            <p>
              <strong>12 Meal Dinner Plan</strong> - This plan is for 90 credits and costs $99. On average, people on
              this plan reserve 12 meals over the course of 30 days.
            </p>
            <p>
              <strong>24 Meal Dinner Plan</strong> - This plan is for 180 credits and costs $179. On average, people on
              this plan reserve 24 meals over the course of 30 days.
            </p>
            <p>
              Unless otherwise noted, plans last for a 30 day cycle and automatically renew after this time or when you
              have less than 10 credits remaining, whichever comes first. You can rollover up to.
            </p>
          </FaqQuestion>
          <FaqQuestion question="How do I use MealPal?" open={open === '#3'} toggle={() => toggle('#3')}>
            <p>
              By purchasing a MealPal membership, you will get access to the MealPal “kitchen”. The MealPal kitchen will
              show all of the different meals restaurants are offering on a given night. In the MealPal kitchen, you can
              reserve the meals you want and select the pick up window you want.
            </p>
            <p>
              In a given day, you can reserve multiple meals from the same restaurant or multiple meals from different
              restaurants.
            </p>
            <p>
              You can reserve meals for the entire week starting at 5:00pm on Sunday. You can reserve until 5:00pm the
              day of the meal. If you wish to change your dinner reservation to a different meal, you can cancel your
              reservation and select another meal as long as you do this in advance of the 5:00pm daily reservation
              cutoff time.
            </p>
            <p>
              Your meal will be ready and waiting for you at your designated pick up time. Skip the line, tell the
              restaurant you are with MealPal and scan to pick up. Enjoy your meal!
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="How does MealPal work for restaurants?"
            open={open === '#4'}
            toggle={() => toggle('#4')}
          >
            <p>
              The most profitable orders restaurants receive are large catering orders. For restaurants, MealPal is
              similar to a catering order. Because the MealPal menu items are limited each day and MealPal sends order
              quantities to restaurants in advance, restaurants see reductions in overhead costs and greater
              efficiencies on MealPal orders.
            </p>
            <p>
              We are committed to being the Most Valuable Partner a restaurant works with. Other third party platforms
              charge restaurants large fees; MealPal partners with restaurants to optimize operations and increase
              profits while reducing costs for customers. We've partnered with over 10,000 restaurants around the world,
              and many of these restaurants actively promote MealPal to their guests, as the restaurant enjoys greater
              efficiencies and profitability with MealPal.
            </p>
          </FaqQuestion>
          <FaqQuestion question="Where can I use MealPal?" open={open === '#5'} toggle={() => toggle('#5')}>
            <p>
              You can get meals at any participating restaurant in the MealPal network in your area. In the suburbs,
              MealPal has partnered with over 50 restaurants in Sudbury, Concord, and Maynard. Nearly every restaurant
              in these towns is available on MealPal!
            </p>
            <p>If you are not in the Boston suburbs, MealPal offers lunch plans in over 20 cities around the world.</p>
          </FaqQuestion>
          <FaqQuestion question="When is MealPal available?" open={open === '#6'} toggle={() => toggle('#6')}>
            <p>
              MealPal is available weekdays excluding holidays. You can pick up your meals between 4:30pm-9:00pm,
              depending on the restaurant’s hours.
            </p>
            <p>
              Want to use MealPal in Sudbury, Concord, or Maynard on Saturday or Sunday? Please email us to let us know
              this feedback - <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>
            </p>
          </FaqQuestion>
          <FaqQuestion question="What does my plan cover?" open={open === '#7'} toggle={() => toggle('#7')}>
            <p>
              Your MealPal membership covers 100% of the cost of your meals. There are no additional charges when you
              pick up your dinner, and MealPal facilitates all of the payment to restaurants.
            </p>
          </FaqQuestion>
          <FaqQuestion question="When does my MealPal start and end?" open={open === '#8'} toggle={() => toggle('#8')}>
            <p>
              Most MealPal memberships last for a 30 day cycle. Your MealPal cycle begins the day you sign up for a
              plan. Your cycle will renew every 30 days or when you have less than 10 credits remaining, whichever comes
              first.
            </p>
            <p>
              You may put your account on hold for up to 90 days in between cycles. You can put your account on hold in
              the “My Account” section or by emailing us at <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>.
            </p>
            <p>
              You can also make changes to your plan or cancel your account by emailing{' '}
              <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>
            </p>
          </FaqQuestion>
          <FaqQuestion question="How do I pickup my meal?" open={open === '#9'} toggle={() => toggle('#9')}>
            <p>
              In order to pickup your meal, you need to have either the{' '}
              <a
                href="https://apps.apple.com/us/app/mealpal-best-meals-around-you/id1123086545"
                target="_blank"
                rel="noreferrer"
              >
                iOS app
              </a>{' '}
              or the{' '}
              <a
                href="https://play.google.com/store/apps/details?id=com.mealpass.mealpal&hl=us"
                target="_blank"
                rel="noreferrer"
              >
                Android app
              </a>{' '}
              installed on your mobile device and have a working camera on your phone.
            </p>
            <p>
              Once at the restaurant, you will open the MealPal app, tap "Pick Up", and then scan a QR code at the
              restaurant and show the restaurant your confirmation screen. This screen will expire after 3 minutes, so
              make sure to scan the QR code just before showing the restaurant your confirmation.
            </p>
            <p>
              You can send a link to a friend to pick up your meal for you. Your friend should click on the link when
              they are at the restaurant in order to scan the QR code.
            </p>
            <p>
              Scanning the QR code is really important. It helps us make sure customers are successfully picking up
              meals, and enables our data algorithm to better predict when customers will arrive so that your meal will
              always be fresh!
            </p>
          </FaqQuestion>
          <FaqQuestion question="Does MealPal deliver?" open={open === '#10'} toggle={() => toggle('#10')}>
            Currently, MealPal is for pick up only. We encourage you to get your neighbors on board and to take turns
            picking up meals to avoid delivery costs. If you really really really want delivery, we’d love to have this
            feedback. Please tell us at <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>
          </FaqQuestion>
          <FaqQuestion
            question="Can I leave a tip for a restaurant?"
            open={open === '#11'}
            toggle={() => toggle('#11')}
          >
            Your MealPal subscription covers all costs. Tipping is not required or expected, and we are paying
            restaurants enough that they are profitable and happy with the MealPal relationship. If a restaurant goes
            above and beyond and you would like to leave them a tip, you will have the option to do so in the app. 100%
            of tips go to the restaurant!
          </FaqQuestion>
          <FaqQuestion question="How do I cancel my account?" open={open === '#12'} toggle={() => toggle('#12')}>
            <p>
              You can make changes in the "My Account" section of the MealPal website or app. You may change your plan,
              add more meals, pause your account, or cancel your membership. You can also make changes by contacting us
              at <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>.
            </p>
          </FaqQuestion>
          <FaqQuestion question="Can I pause or hold my account?" open={open === '#13'} toggle={() => toggle('#13')}>
            <p>
              Yes. In "My Account", click "change plan for next cycle" and follow steps for "hold my account." You can
              also email <a href="mailto:hello@mealpal.com">hello@mealpal.com</a> to pause your account. You can put
              your account on hold for up to three months.
            </p>
            <p>
              The hold will start at the end of your current cycle. You can remove the hold or change your end date at
              any time.
            </p>
          </FaqQuestion>
          <FaqQuestion question="How can I change my password?" open={open === '#14'} toggle={() => toggle('#14')}>
            Log out of your account and go to <a href={`${process.env.GATSBY_SERVER_URL}/login`}>the log in screen</a>.
            Then, select the “Forgot?” button to updateyour password.
          </FaqQuestion>
          <FaqQuestion question="Can I give MealPal as a gift?" open={open === '#15'} toggle={() => toggle('#15')}>
            Yes! Please email us at <a href="mailto:hello@mealpal.com">hello@mealpal.com</a> and we can help set you up
            with a gift account. Additionally, we are always looking for ways to support the communities we are in. If
            there is someone you think we should gift a membership to, please let us know.
          </FaqQuestion>
        </div>
      </div>
    </>
  )
}

export default SuburbFAQContainer
