import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import SuburbFAQContainer from '../components/suburb-faq/Container'

const SuburbFaq = () => {
  return (
    <Layout>
      <Router>
        <SuburbFAQContainer path="/suburb-faq" />
      </Router>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default SuburbFaq
