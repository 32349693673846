import React from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

import useHomepage from '../hooks/useHomepage'
import { UrlService } from '../services'
import SlideDown from './slidedown/Container'

type Props = {
  customClass?: string
  question: string
  children: React.ReactNode
  open?: boolean
  toggle?: () => void
  utmContent?: string
}

const FaqQuestion: React.FC<Props> = ({ customClass, question, children, open, toggle, utmContent }) => {
  const { activeCityName } = useHomepage()

  const signupUrl = UrlService.signupUrl({ utmContent: utmContent || '', cityName: activeCityName })

  return (
    <>
      <div className={`question-group ${customClass} ${open && 'question-group--open'}`}>
        <div className="question" onClick={toggle}>
          {!open && (
            <h4>
              {question} <FaChevronDown />
            </h4>
          )}
          {open && (
            <h4>
              {question} <FaChevronUp />
            </h4>
          )}
        </div>
        <SlideDown>
          {open ? (
            <div className="answer">
              {children}
              {utmContent ? (
                <div className="join-now">
                  <a href={signupUrl} className="red-cta">
                    JOIN MEALPAL TODAY
                  </a>
                </div>
              ) : null}
            </div>
          ) : null}
        </SlideDown>
      </div>
    </>
  )
}

export default FaqQuestion
